import Loading from "../../Loading";
import React, {useEffect, useState} from "react";
//@ts-ignore
import {useTranslation} from "react-i18next";
//@ts-ignore
import {useDebounce} from "use-debounce";
import CustomerRow from "./CustomerRow";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import CustomerDetails from "./CustomerDetails";
import FancyCheckbox from "../FancyCheckbox";
import {DateTime} from "luxon";
//@ts-ignore
import {useNavigate, useParams} from "react-router-dom";

interface SearchQuery {
  text: string;
}

interface SearchSettings {
  pageIndex: number;
  pageSize: number;
}
interface SearchFilter {
  lockedOut: boolean;
  emailConfirmed: boolean |null;
}
interface Params {
  userId: string;
}

const Customers = () => {
  const params = useParams<Params>();
  const navigate = useNavigate();
  const {targetRef, isIntersecting} = useIntersectionObserver({threshold: 0.5});
  const [loading, setLoading] = useState(true);
  const [showFilterPanel, setShowFilterPanel] = useState(false);
  const [results, setResults] = useState<any[]>([]);
  const [query, setQuery] = useState<SearchQuery>({text: ""});
  const [settings, setSettings] = useState<SearchSettings>({
    pageIndex: 0,
    pageSize: 20
  });
  
  const [filter, setFilter] = useState<SearchFilter>({
    lockedOut: false,
    emailConfirmed: null
  });

  const toggleFilterPanel = (e:any) =>{
    e.preventDefault();
    setShowFilterPanel(!showFilterPanel);
  }

  const [debouncedQuery] = useDebounce(query, 1000);
  const [debouncedFilter] = useDebounce(filter, 500);

  const getQueryString = (pageIndex?:number) => {
    const queryString = new URLSearchParams();
    //query
    queryString.set('query', debouncedQuery.text);

    //filter
    if(filter.lockedOut){
      queryString.set('fLockedOut', filter.lockedOut.toString());
    }

    if(filter.emailConfirmed !== null) {
      queryString.set('fEmailConfirmed', filter.emailConfirmed.toString());
    }
    //settings
    queryString.set('pageSize', settings.pageSize.toString());
    queryString.set('pageIndex', (pageIndex !== undefined? pageIndex : settings.pageIndex).toString());
    
    return queryString;
  }
  
  useEffect(() => {
    //query changed
    settings.pageIndex = 0;
    fetchData();
  }, [debouncedQuery, debouncedFilter]);

  const fetchData = () => {
    //console.log('fetching data pageIndex:', settings.pageIndex);
    const queryString = getQueryString();

    fetch(`api/pick-n-pay/customers/search?${queryString.toString()}`)
      .then(response => response.json())
      .then(data => {
        if(data.pageIndex != 0) {
          setResults([...results, ...data.items as any[]]);
        } else { 
          setResults(data.items as any[]);
        }
        setLoading(false);
      });
  }

  useEffect(() => {
    if (!isIntersecting) {
      return;
    }

    //next page should be fetched
    //console.log("fetching next page");
    setSettings({...settings, pageIndex: settings.pageIndex + 1});
    fetchData();
  }, [isIntersecting])
  
  const downloadExport = ()=>{
    fetch(`/api/pick-n-pay/customers/export?${getQueryString(0)}`)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `customers-${DateTime.now().toFormat("yyyy-MM-dd")}.csv`;
        a.click();
      });
  };
  
  const clearFilters = (e:any)=>{
    e.preventDefault();
    setFilter({ 
      lockedOut: false, 
      emailConfirmed: null
    });
  }

  const {t} = useTranslation();
  return (
    <div className={"pick-n-pay customers-page"}>
      <h1>{t('pick-n-pay.customers.list.header')}</h1>
      <div className={"filter-container"}>
        <form className={"filters"}>
          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t(`pick-n-pay.customers.list.query.label`)}</label>
            <input className={"form-control"}
                   value={query.text}
                   placeholder={t(`pick-n-pay.customers.list.query.placeholder`)}
                   type={"search"} onChange={(e) => setQuery({...query, text: e.target.value})}/>
            <button className={"btn btn-toggle-filters"} onClick={toggleFilterPanel}>{t(`pick-n-pay.filter-panel.filterBtnText`)}</button>
            <p className={"form-info"}>{t(`pick-n-pay.customers.list.query.description`)}</p>
          </div>

          <div className={`filter-panel ${showFilterPanel ? 'visible' : 'hidden'}`}>
            <div className="filter-panel-header">
              <h2>{t(`pick-n-pay.filter-panel.header`)}</h2>
              <button onClick={toggleFilterPanel} className={"btn btn-close"}>{t(`pick-n-pay.filter-panel.close`)}</button>
            </div>
            <div className={"input-group"}>
              <label>{t(`pick-n-pay.customers.list.lockedOut.label`)}</label>
              <FancyCheckbox checked={filter.lockedOut}
                             name={"fLockedOut"} 
                             label={t(`pick-n-pay.customers.list.lockedOut.activate`)}
                             onChange={(e) => {
                               setFilter({...filter, lockedOut: e.target.checked})
                             }}/>
              <p className={"form-info"}>{t(`pick-n-pay.customers.list.lockedOut.description`)}</p>
            </div>

            <div className={"input-group"}>
              <label>{t(`pick-n-pay.customers.list.emailConfirmed.label`)}</label>
              <select autoComplete={"off"} value={filter.emailConfirmed?.toString() ?? ""} onChange={(e) => {
                if (e.target.value === "") {
                  setFilter({...filter, emailConfirmed: null})
                } else {
                  setFilter({...filter, emailConfirmed: e.target.value.toLowerCase() == "true"});
                }
              }}>
                <option value={""}>{t(`pick-n-pay.customers.list.emailConfirmed.empty`)}</option>
                <option value={"true"}>{t(`pick-n-pay.customers.list.emailConfirmed.confirmed`)}</option>
                <option value={"false"}>{t(`pick-n-pay.customers.list.emailConfirmed.unconfirmed`)}</option>
              </select>
              <p className={"form-info"}>{t(`pick-n-pay.customers.list.emailConfirmed.description`)}</p>
            </div>
            <div className={"btn-group filter-panel-footer"}>
              <button onClick={clearFilters} className={"btn btn-clear"}>{t(`pick-n-pay.filter-panel.btnClear`)}</button>
              <button onClick={toggleFilterPanel} className={"btn btn-primary"}>{t(`pick-n-pay.filter-panel.btnDone`)}</button>
            </div>
          </div>
        </form>
      </div>

      {loading && <Loading visible={true}/>}

      {!loading && (
        <div className={"container"}>
          <button onClick={downloadExport} className={"btn"}>
            {t('pick-n-pay.customers.list.export.text')}
          </button>
          <div className={"customer-search-result search-result-container"}>
            <div className={"search-result-header"}>
              <span>{t('pick-n-pay.customers.list.list-headers.name')}</span>
              <span>{t('pick-n-pay.customers.list.list-headers.customerCode')}</span>
              <span>{t('pick-n-pay.customers.list.list-headers.email')}</span>
              <span></span>
            </div>
            <ol className={"search-result"}>
              {results.map((customer) => (
                <CustomerRow customer={customer} key={customer.id} onClick={(customer:any)=>{
                  navigate(`/pick-n-pay/customers/${encodeURIComponent(customer.id)}`);
                }}/>
              ))}
            </ol>
            <div className={"scroll-observer"} ref={targetRef}></div>
          </div>
        </div>
      )}

    </div>
  )
}

export default Customers;